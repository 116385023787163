﻿.modal {
  &-content {
    border-radius: 1.2rem;
  }

  &-body {
    color: var(--dark-colour);

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 2rem;
      margin: 6rem 6rem;
      text-align: center;
    }

    &--no-margin {
      & > div {
        margin: 0;
      }
    }
  }

  & i[data-bs-dismiss] {
    text-align: right;
    float: right;
    cursor: pointer;
  }

  & h1, h2, h3, h4, h5, label, p, a:not(.button) {
    color: var(--dark-color)
  }

  & h5 {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
}
