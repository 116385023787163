﻿@font-face {
  font-family: "Havelock Titling";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/HavelockTitlingLight.eot");
  src: url("../assets/fonts/HavelockTitlingLight.eot") format("embedded-opentype"), url("../assets/fonts/HavelockTitlingLight.woff2") format("woff2"), url("../assets/fonts/HavelockTitlingLight.woff") format("woff"), url("../assets/fonts/HavelockTitlingLight.ttf") format("truetype"), url("../assets/fonts/HavelockTitlingLight.svg#HavelockTitlingLight") format("svg"); }

@font-face {
  font-family: "Havelock Titling";
  font-weight: 400;
  src: url("../assets/fonts/HavelockTitlingRegular.eot");
  src: url("../assets/fonts/HavelockTitlingRegular.eot") format("embedded-opentype"), url("../assets/fonts/HavelockTitlingRegular.woff2") format("woff2"), url("../assets/fonts/HavelockTitlingRegular.woff") format("woff"), url("../assets/fonts/HavelockTitlingRegular.ttf") format("truetype"), url("../assets/fonts/HavelockTitlingRegular.svg#HavelockTitlingRegular") format("svg"); }

@font-face {
  font-family: "Havelock Titling";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/HavelockTitlingMedium.eot");
  src: url("../assets/fonts/HavelockTitlingMedium.eot") format("embedded-opentype"), url("../assets/fonts/HavelockTitlingMedium.woff2") format("woff2"), url("../assets/fonts/HavelockTitlingMedium.woff") format("woff"), url("../assets/fonts/HavelockTitlingMedium.ttf") format("truetype"), url("../assets/fonts/HavelockTitlingMedium.svg#HavelockTitlingMedium") format("svg"); }

@font-face {
  font-family: "Havelock Titling";
  font-weight: 700;
  src: url("../assets/fonts/HavelockTitlingBold.eot");
  src: url("../assets/fonts/HavelockTitlingBold.eot") format("embedded-opentype"), url("../assets/fonts/HavelockTitlingBold.woff2") format("woff2"), url("../assets/fonts/HavelockTitlingBold.woff") format("woff"), url("../assets/fonts/HavelockTitlingBold.ttf") format("truetype"), url("../assets/fonts/HavelockTitlingBold.svg#HavelockTitlingBold") format("svg"); }

@font-face {
  font-family: "Havelock Titling";
  font-weight: 900;
  src: url("../assets/fonts/HavelockTitlingBlack.eot");
  src: url("../assets/fonts/HavelockTitlingBlack.eot") format("embedded-opentype"), url("../assets/fonts/HavelockTitlingBlack.woff2") format("woff2"), url("../assets/fonts/HavelockTitlingBlack.woff") format("woff"), url("../assets/fonts/HavelockTitlingBlack.ttf") format("truetype"), url("../assets/fonts/HavelockTitlingBlack.svg#HavelockTitlingBlack") format("svg"); }

@font-face {
  font-family: SairaRegular;
  font-style: normal;
  font-weight: 400;
  src: local('SairaRegular'), url(/assets/fonts/Saira-Regular.ttf) format('truetype');
}
