.or-para {
  margin: 0;
  font-family: 'Havelock Titling';
  font-size: 2rem;
  font-weight: bold;
  position: relative;

 &::before,
 &::after {
  content: "";
  position: absolute;
  bottom: 13px;
  width: 43%;
  border-bottom: 1px solid #6D7280;
 }

 &::before {
    left: 0;
 }

 &::after {
    right: 0;
 }
}

.c-pointer{
  cursor: pointer;
}
