// Paths
$img-path: '/assets/images' !default;
$png-path: '/assets/images/png' !default;
$svg-path: '/assets/images/svg' !default;
$font-path: '/assets/fonts' !default;

// Fonts
$font-family: 'SairaRegular';
$font-family-secondary: 'Havelock Titling';
$base-font-size: 62.5%;

$xl-breakpoint: 1400px;
$lg-breakpoint: 1200px;
$md-breakpoint: 992px;
$tb-breakpoint: 768px;
$sm-breakpoint: 576px;

:root {
  --primary-text-colour: #fff;
  --secondary-text-dark-colour: #6D7280;
  --secondary-text-light-colour: #B7B8BC;
  --primary-text-alt-colour: #303101;

  --dark-colour: #012732;
  --dark-grey: #333;
  --brown-colour: #8b2d8c;
  --orange-colour: #d34eaa;
  --green-colour: #00DA57;
  --yellow-colour: #f3da7e;
  --secondary-blue: #5472CA;

  --dark: #1a1a1a; 
  --white: #fff;
}

$form-select-indicator-color: #fff;
