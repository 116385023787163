.card {
    border-radius: 1rem;
    border: none;
    height: 100%;

  .card__image {
    background: grey;
    height: 15.35rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .card__detail {
    padding: 1.7rem;
    font-size: 1.3rem;

    h3 {
        color: var(--dark-grey);
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

  }
}
