@mixin dimension($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin glass {
  position: relative;
  background-color: transparent;
  border-radius: 2rem;
  padding: 1.5rem 1.5rem;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: inherit;
    background: linear-gradient(120.15deg, #f88ced 12.27%, rgba(218, 198, 220, 0.36) 29.54%, rgba(162, 113, 167, 0.38) 55.12%, rgba(117, 43, 124, 0.44) 76.05%), linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0.28;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(35px);
    border-radius: inherit;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: linear-gradient(
      130.48deg,
      rgba(255, 255, 255, 0.8) 14.79%,
      rgba(255, 255, 255, 0.1) 72.36%
    );
    opacity: 0.28;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(35px);
    border-radius: inherit;
    z-index: 0;
  }
}

