body,
html {
  background-color: #000;
  color: var(--secondary-text-light-colour);
  font-family: $font-family;
  font-size: $base-font-size;
  letter-spacing: 1.5px;
}

body {
  font-size: 1.8rem;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--white);
  font-family: $font-family-secondary;
  text-transform: uppercase;
}

.head {
  margin-bottom: 3.2rem;

  &--xlarge {

    font-size: 3.2rem;

    @media screen and (min-width: $md-breakpoint) {
      font-size: 5.2rem;
    }
  }

  &--large {
    font-size: 4.2rem;
  }

  &--medium {
    font-size: 3.2rem;
  }

  &--small {
    font-size: 2rem;
  }

  &--bold {
    font-weight: bold;
  }
}

p {
  color: var(--secondary-text-light-colour);
}

table {
  width: 100%;

  & tbody {
    color: var(--secondary-text-light-colour);
  }

  & td,
  th {
    padding: 0 0.5rem 1.5rem;
  }

  & th {
    color: var(--white);
    font-weight: 600;
  }
}

label {
  color: var(--white);
  font-weight: bold;
}

del {
  text-decoration: line-through;
}