﻿// Main Layout
.main-container {
  //display: flex;
  //flex-direction: column;
  min-height: 100vh;
  background: url('#{$img-path}/background-bottom.png'), url('#{$img-path}/background-top.png');
  background-size: 100% auto, 100% auto;
  background-repeat: no-repeat, no-repeat;
  background-position: bottom, top;

  &.profile-section {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('#{$img-path}/background-bottom.png'), url('#{$img-path}/background-top.png');
    background-size: 100% auto, 100% auto;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, top;
  }

  & > main {
    flex-grow: 1;
    padding: 0 5rem;

    @media screen and (max-width: $md-breakpoint) {
      padding: 0 3rem;
    }


  }

  footer {
    /* Set the position of the footer */
    position: sticky;
    bottom: 0;


    /* Set the height and width of the footer */
    height: 5rem;
    width: 100%;


    @media screen and (max-width: $sm-breakpoint) {
      height: auto;
    }
  }

  @media screen and (min-height: 100vh) {
    /* Hide the sticky footer if there is enough content */
    footer {
      position: static;
    }
  }
}

.nav-with-overflow {
  position: relative;

  &__list {
    display: flex;
    margin: 0 0 -1.2rem;
    padding: 0;
    max-width: var(--lg-breakpoint);
  }

  &__list-item {
    cursor: pointer;
    margin-right: 4.8rem;

    & > * {
      color: var(--secondary-text-dark-colour);
      text-decoration: none;
      padding-bottom: 1.2rem;
      display: block;
    }

    &:hover, &.active {
      color: var(--primary-text-colour);
      border-bottom: 3px solid var(--primary-text-colour);

      & > * {
        font-weight: 500;
        color: var(--primary-text-colour);
      }
    }
  }

  &__hr {
    height: 3px;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
    position: relative;
    background-color: var(--primary-text-colour);
  }
}

.slide-container {
  & > div {
    display: grid;
    overflow: hidden;

    & > div {
      opacity: 0;
      pointer-events: none;
      left: 0;
      top: 0;
      width: 100%;

      &.active {
        opacity: 1;
        pointer-events: auto;
        position: relative;
      }
    }
  }
}

.banner {
  width: calc(100vw);
  margin-left: calc(-50vw + 50%);
  height: 40rem;

  &--empty {
    background-color: var(--white);
    position: relative;

    &::after {
      content: 'Banner';
      position: absolute;
      font-weight: bold;
      display: grid;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: var(--dark-colour);
    }
  }
}

.logo {
  border-radius: 100%;
  border: 2px solid var(--white);
  width: 17rem;
  height: 17rem;
  position: relative;
  margin-top: -6rem;
  margin-bottom: 6rem;
  background-position: center;
  background-size: cover;

  &--empty {
    background-color: var(--secondary-text-light-colour);
    position: relative;

    &::after {
      content: 'PFP';
      position: absolute;
      font-weight: bold;
      display: grid;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: var(--dark-colour);
    }
  }
}
