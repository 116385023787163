﻿input {

  &[type=text],
  &[type=email],
  &[type=number],
  &[type=password] {
    height: 4.3rem;
  }

  &[type=checkbox] {
    border: 1px solid var(--secondary-text-light-colour);
    border-radius: 4px;
    width: 2.8rem;
    height: 2.8rem;
    position: relative;

    &:hover {
      background-color: var(--secondary-text-light-colour);
    }

    &:checked {
      background: var(--white);

      &:after {
        content: '✓';
        text-align: center;
        vertical-align: middle;
        position: absolute;
        height: 1em;
        width: 1em;
        line-height: 1;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: var(--dark-colour);
      }
    }
  }

  &[type=radio] {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    appearance: auto;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--secondary-text-light-colour);
    opacity: 1;
    /* Firefox */
    filter: none;
  }
}

input[type=text],
input[type=email],
input[type=number],
input[type=password],
select,
textarea {
  background: #000000CC;
}

input[type=text],
input[type=email],
input[type=number],
input[type=password],
textarea {
  border-radius: 4px;
  border: 2px solid var(--secondary-text-dark-colour);
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.18));
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  width: 100%;

  &:read-only {
    border: none;
    padding: 0.5rem 0;
  }
}

textarea {
  height: 13.3rem;
}


.button {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1rem 2rem;
  white-space: nowrap;
  text-decoration: none;
  color: var(--white);
  text-align: center;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: inherit;
    background: radial-gradient(91.74% 2007.5% at 93.8% -10.83%, rgba(250, 250, 250, 0) 0%, #792d9b 50%, rgba(122, 102, 29, 0) 100%), linear-gradient(0deg, #f3da7e, #f3da7e);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    color: var(--white);
    text-decoration: none;
  }

  &--primary {
    font-size: 1.8rem;
  }

  &--secondary {
    font-size: 1.6rem;
  }

  &--tertairy,
  &--tertairy-alt {
    font-size: 1.6rem;
    background-color: var(--white);
    border: 2px solid #B7B8BC;
    color: var(--dark);
    text-transform: none;

    &::before {
      content: none;
    }
  }

  &--tertairy-alt {
    border: 2px solid var(--white);
    background-color: var(--dark);
    color: var(--white);
  }

  &--dark {
    background-color: var(--dark);
    color: var(--white);
  }

  &:disabled,
  &--disabled {
    opacity: 0.6;
  }
}

.form-input-group {
  &>*:first-child {
    margin-right: 1rem;
  }

  @media screen and (max-width: $md-breakpoint) {

    &>input:not([type=checkbox]):not(:read-only),
    &>.button {
      width: 100%;

      &:first-child {
        margin: 0 0 1rem;
      }
    }
  }

  &--checkbox {
    display: flex;
    align-items: center;
  }
}

.forgot-password {
  text-align: right;
  font-size: 1.4rem;
  margin-top: -1rem;
}

.form-error {
  text-align: left;
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #bb0000;

  &+.forgot-password {
    margin-top: 1rem;
  }
}

.x-select {
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.7rem;
  font-weight: 400;
  padding: 1rem;
  padding-right: 3rem;
  line-height: 1.8;
  color: var(--white);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  background-repeat: no-repeat;
  background-position: right -0.25rem center;
  background-size: 2.4rem 1.2rem;
  border: 1px solid var(--white);
  border-radius: 0.5rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; 
  cursor: pointer;

  & option {
    background-color: var(--white);
    color: var(--dark-grey);
    font-size: 1.6rem;
  }

}




.x-select-label {
  margin-right: 1rem;
}

.search-results__item {
  margin-top: 2rem;
}

.edit-create-form {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 10rem;

  @media screen and (max-width: $md-breakpoint) {
    flex-direction: column;
  }

  &>div {
    &:first-child {
      max-width: 50rem;

      @media screen and (min-width: $md-breakpoint) {
        margin-right: 3rem;
      }
    }
  }

  .form__group {
    .form-input-group {
      margin-top: 0.7rem;
      margin-bottom: 2.5rem;
    }
  }

  .x-select {
    width: 100%;
  }
}

.image-upload__container {
  border: 0.2rem dashed var(--secondary-text-dark-colour);
  border-radius: 1rem;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-bottom: 3rem;

  @media screen and (min-width: $md-breakpoint) {
    padding: 4rem;
  }

  i.fa-image {
    font-size: 8rem;
  }

  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background-color: var(--white);
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    color: var(--dark);
    cursor: pointer;
    transition: background .2s ease-in-out;
    font-weight: bold;

  }

  input {
    width: 100%;
    margin-top: 2rem;

    @-moz-document url-prefix() {
      width: 100%;
    }
  }
}

button[type=submit] {
  width: 100%;
  margin-top: 1rem;
}

.pagination {
  margin-top: 7rem;

  .page-item {


    .page-link {
      padding: 1rem;
      border: none;
    }

&.disabled {
  cursor: not-allowed;
}
  }
}

