﻿.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.button-group {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;

  @media screen and (max-width: $md-breakpoint) {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  & > * {
    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
    }
  }
}

.empty-state-para {
  margin-bottom: 7rem;
}

.link {
  text-decoration: underline;
  color: var(--secondary-blue);
  cursor: pointer;
}
